// Dependencies
import React, { useState } from "react";
import moment from "moment";
import { Link } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings";
import useMicrocopy from "../hooks/useMicrocopy";
// Components
import Logo from "../components/Logo";
import RichText from "../components/RichText";

const year = moment().format('YYYY');

function useInput() {
  const [value, set] = useState("");
  const handleChange = ({ target }) => {
    set(target.value)
  }
  return [value, handleChange]
}

const NewsletterForm = ({ message }) => {

  const [email, updateEmail] = useInput();

  return (
    <div className="newsletter-form">

      <form className="mb" action="https://gmail.us2.list-manage.com/subscribe/post?u=d65af3ec5365285ef5dad4e19&amp;id=687fe8ffec" method="post" name="mc-embedded-subscribe-form" target="_blank" noValidate>
        <input type="email" value={email} onChange={updateEmail} name="EMAIL" className="" placeholder="Your e-mail address" />
        <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true"><input type="text" name="b_d65af3ec5365285ef5dad4e19_687fe8ffec" tabIndex="-1" value="" readOnly /></div>
        <input type="submit" value="Subscribe" />
      </form>
      <h6>{message}</h6>
    </div>

  )
}

const Footer = ({ isPage, locale }) => {

  // Content
  const { footerLinks, manifesto, signUpMessage } = useSettings(locale);
	const { allRightsReserved, soundspacememoryexperience } = useMicrocopy(locale);

  return (
    <footer className={isPage ? 'is-page' : ''}>
      <div className="grid">

        <div className="links p2">
          <div className="logo">
            <Logo />
          </div>
          <ul>
            {footerLinks.map(({ externalLink, link, label }, index) => {
              return (
                <li key={`footer-link-${index}`}>
                  {externalLink ? (
                    <a href={externalLink} target="_blank">{label}</a>
                  ) : link ? (
                    <Link to={link.category ? `/${link.category.slug}/${link.slug}` : `/${link.slug}`}>{label}</Link>
                  ) : label}
                </li>
              )
            })}
          </ul>
          <NewsletterForm message={signUpMessage.text} />
          <div className="outer">
            <h6>Website by <a href="https://official.business" target="_blank">Official Business</a>.</h6>
          </div>
        </div>
        <div className="manifesto p3">
          <div>
            <p>
              MSCTY © {year} <br />
              {allRightsReserved}<br />
              {soundspacememoryexperience} <br />
            </p>
            <RichText content={manifesto} />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
