// Dependencies
import React from "react"
import { Link } from "gatsby"
import { EN, JP } from "../constants/locales"

const LocaleLink = ({ 
	to, 
	locale = 'en-US', 
	replace,
	...props 
}) => {

	let pathname
	let path = `/${locale || 'en-US'}${to}`

	// TODO...
	// if (replace) {
	// 	if (locale === JP) {
	// 		pathname = to.split(JP)[1]
	// 	} else {
	// 		pathname = to.split(EN)[1]
	// 	}
	// 	path = locale === EN ? `/${JP}${pathname}` : `/${EN}${pathname}`
	// }

	return (
		<Link to={path} {...props} />
	)
}

export default LocaleLink
