// Dependencies
import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";
// Hooks & Helpers
import useSettings from "../hooks/useSettings";
// Components
import { EN, JP } from '../constants/locales'

function hexToRGB(h) {
  let r = 0, g = 0, b = 0;

  if (!h) return;

  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];

  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }

  return `${+r}, ${+g}, ${+b}`
  // return "rgb("+ +r + "," + +g + "," + +b + ")";
}

function Meta({ title, description, keywords, shareImage, colours, locale }) {

	const lang = locale && locale === JP ? 'jp' : 'en' 

  const { defaultTitle, defaultDescription, defaultShareImage, defaultKeywords } = useSettings(locale);
	// Colours

  const metaTitle = title || defaultTitle;
  const metaDescription = description?.text || defaultDescription.text;
  const metaImage = (shareImage && shareImage.file.url) || (defaultShareImage && defaultShareImage.file.url) || '';
  // const backgroundColor = (colours && colours.background) || (colours && `rgb(${hexToRGB(colours.secondary)}, 0.1)`) || 'var(--grey)';
	const metaKeywords = keywords || defaultKeywords;

  const css = `
    :root {
      --primary: ${colours ? `rgb(${hexToRGB(colours.primary)})` : 'var(--black)'};
      --secondary: ${colours ? `rgb(${hexToRGB(colours.secondary)})` : 'var(--white)'};
      --background: var(--grey);
    }
  `;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      encodeSpecialCharacters={false}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
				{
					name: `keywords`,
					content: metaKeywords
				},
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: `https:${metaImage}`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '@MSCTY_SPACE',
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `https:${metaImage}`,
        },
      ]}
      style={[{
        "cssText": css
      }]}
    />
  );
};

Meta.defaultProps = {
  description: ``,
};

Meta.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  shareImage: PropTypes.object
};

export default Meta;
