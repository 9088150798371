// Dependencies
import React from "react";

const Logo = ({ fill = "#0B0B0A" }) => {

  return (
    <svg width="130" height="79" viewBox="0 0 130 79" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g fill={fill}>
        <path d="M20.0135 6.90165C12.7677 6.90165 6.86709 12.8214 6.86709 20.1033C6.86709 27.3852 12.7621 33.305 20.0135 33.305C27.2649 33.305 33.1599 27.3852 33.1599 20.1033C33.1599 12.8214 27.2649 6.90165 20.0135 6.90165ZM20.0135 40.2009C8.97526 40.2009 0 31.1823 0 20.0976C0 9.013 8.97526 0 20.0135 0C31.0517 0 40.027 9.01868 40.027 20.0976C40.027 31.1766 31.0517 40.2009 20.0135 40.2009Z" />
        <path d="M20.0135 53.38C19.307 53.38 18.7305 53.9589 18.7305 54.6684C18.7305 55.3778 19.307 55.9567 20.0135 55.9567C20.72 55.9567 21.2965 55.3778 21.2965 54.6684C21.2965 53.9532 20.7256 53.38 20.0135 53.38ZM20.0135 62.8527C15.5202 62.8527 11.8577 59.1805 11.8577 54.6627C11.8577 50.1448 15.5145 46.4727 20.0135 46.4727C24.5124 46.4727 28.1692 50.1448 28.1692 54.6627C28.1692 59.1805 24.5124 62.8527 20.0135 62.8527Z" />
        <path d="M130 79H96.7045V72.0984H123.133V20.1033C123.133 12.8271 117.238 6.90165 109.987 6.90165C102.741 6.90165 96.8401 12.8214 96.8401 20.1033V54.6683C96.8401 59.1805 93.1833 62.8583 88.6844 62.8583C84.1855 62.8583 80.5287 59.1861 80.5287 54.6683V20.1033C80.5287 12.8271 74.6337 6.90165 67.3823 6.90165C60.1309 6.90165 54.2415 12.8214 54.2415 20.1033V72.104H80.67V79.0057H47.3745V20.1033C47.3745 9.01868 56.3497 0 67.3879 0C78.4261 0 87.4014 9.01868 87.4014 20.1033V54.6683C87.4014 55.3778 87.9779 55.9567 88.6844 55.9567C89.3909 55.9567 89.9674 55.3778 89.9674 54.6683V20.1033C89.973 9.01868 98.9483 0 109.987 0C121.025 0 130 9.01868 130 20.1033V79Z" />
      </g>
    </svg>
  )
}

export default Logo
