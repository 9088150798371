// Dependencies
import React from "react";
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks & Helpers
import useMount from "../hooks/useMount";
// Components
// import Image from "gatsby-image";
import { Img } from "./Image";
import GradientMap from "../components/GradientMap";

const Artwork = ({ id, context = "context", imageBack, imageFront, colours, configuration }) => {

  const from = colours ? colours.primary : '#000000';
  const to = colours ? colours.secondary : '#FFFFFF';

	const ready = useMount()

  // Style
  const {
    positionX,
    positionY,
    backSkew,
    frontSkew,
    backOrientation,
    frontOrientation,
    backFilter,
    frontFilter,
  } = configuration || {};

  return (
		ready && (
			<div className={`artwork back-${backOrientation} front-${frontOrientation}`}>
				<div className="back-image">
					<div className="img-wrapper">
						{imageBack ?(
							<div className={`img`}>
								<GradientMap id={`filter-${id}-back-${context}`} from={backFilter !== 'duotone-inverted' ? from : to} to={backFilter !== 'duotone-inverted' ? to : from} />
								<div className={`mask ${backSkew}`} style={{ backgroundColor: from }}>
									<div className="filter" style={{
										filter: (backFilter === 'duotone') || (backFilter === 'duotone-inverted') ? `url(#filter-${id}-back-${context})` : (backFilter === 'b-w') ? `grayscale(1)` : `none`
									}}>
										{/* <Image {...imageBack} /> */}
										<Img image={imageBack.large} alt="" />
									</div>
								</div>
							</div>
						) : (
							<div className="img">
								<div className={`mask ${backSkew}`} style={{ backgroundColor: from }} />
							</div>
						)}
					</div>
				</div>
				{imageFront && (
					<div className={`front-image-wrapper ${positionX} ${positionY}`}>
						<div className="front-image">
							<div className="img-wrapper">
								<div className={`img`}>
									<GradientMap id={`filter-${id}-front-${context}`} from={frontFilter !== 'duotone-inverted' ? from : to} to={frontFilter !== 'duotone-inverted' ? to : from} />
									<div className={`mask ${frontSkew}`} style={{ backgroundColor: to }}>
										<div className="filter" style={{
											filter: (frontFilter === 'duotone') || (frontFilter === 'duotone-inverted') ? `url(#filter-${id}-front-${context})` : (frontFilter === 'b-w') ? `grayscale(1)` : `none`
										}}>
											{/* <Image {...imageFront} /> */}
											<Img image={imageFront.large} alt="" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		)
  )
}

export default Artwork

export const ContentfulArtworkFragment = graphql`
  fragment ContentfulArtworkFragment on ContentfulArtwork {
		__typename
		contentful_id
    id
    ## Image (Back)
    imageBack {
      description
      file {
        url
        contentType
        details {
          image {
            width
            height
          }
        }
      }
			large: gatsbyImageData(
				layout: CONSTRAINED
				width: 800
			)
			square: gatsbyImageData(
				layout: CONSTRAINED
				width: 200
				aspectRatio: 1
			)
    }
    ## Image (Front)
    imageFront {
      description
      file {
        url
        contentType
        details {
          image {
            width
            height
          }
        }
      }
			large: gatsbyImageData(
				layout: CONSTRAINED
				width: 1200
			)
			square: gatsbyImageData(
				layout: CONSTRAINED
				width: 200
				aspectRatio: 1
			)
    }
    ## Colours
    colours {
      primary
      secondary
    }
    ## Configuration
    configuration {
      positionX
      positionY
      backSkew
      frontSkew
      backOrientation
      frontOrientation
      backFilter
      frontFilter
    }
  }
`
