// Dependencies
import React, { useState, useRef } from "react";
import { useChain, useTransition, animated } from 'react-spring';
import { easeExpOut } from 'd3-ease';
import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks
import useSettings from "../hooks/useSettings";
// Components
import { HeaderContents } from "./Header";
import Link from "./LocaleLink";

export function useMenuState() {
  const [open, set] = useState(false);
  return [open, set];
}

const MenuLink = ({ link, label, locale }) => (
  <Link to={link.category ? `/${link.category.slug}/${link.slug}` : `/${link.slug}`} locale={locale}>{label}</Link>
)

const Menu = ({ ux, locale }) => {
  // Content
  const { menu } = useSettings(locale);
  // useLockBodyScroll()

  // UX
	// eslint-disable-next-line
  const [ref, transitions] = ux;

  return (
    <div className="menu-grid padx4">
      {menu.map((entry, menuIndex) => (
        transitions.map(({ item, key, props }) =>
          item && (
            <animated.ul className="nav__group" key={key} style={props}>
              <li><h3>{entry.title}</h3></li>
              {entry.menuItems.map((item, itemIndex) => (
                <li key={`item-${itemIndex}`}>
                  {item.link ? (
                    <MenuLink {...item} locale={locale} />
                  ) : item.internalLink ? (
                    <Link to={item.internalLink} locale={locale}>{item.label}</Link>
                  ) : item.externalLink ? (
                    <a href={item.externalLink} target="_blank">{item.label}</a>
                  ) : (
                    <span>{item.label}</span>
                  )}
                </li>
              ))}
            </animated.ul>
          )
        )
      ))}
    </div>
  )
}

const MenuWrapper = ({ open, handleMenuClick, locale }) => {

  // UX
  const config = {
    mass: 1,
    duration: 800,
    tension: 260,
    friction: 30,
    easing: easeExpOut
  };

  const transitionsRef = useRef();
  const transitions = useTransition(open, null, {
    config: config,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    ref: transitionsRef
  });

  const subMenuTransitionsRef = useRef();
  const subMenuTransitions = useTransition(open, null, {
    config: config,
    from: { transform: 'translateY(10%)' },
    enter: { transform: 'translateY(0%)' },
    leave: { transform: 'translateY(0%)' },
    ref: subMenuTransitionsRef
  });

  useChain([transitionsRef, subMenuTransitionsRef], [0, 0.5]);

  return transitions.map(({ item, key, props }) =>
    item && (
      <React.Fragment key={key}>
        <animated.div className="menu-bg-difference pfix fs" style={props} />
        <animated.nav style={props}>
          <HeaderContents hasManifesto handleMenuClick={handleMenuClick} locale={locale} />
          <Menu ux={[subMenuTransitionsRef, subMenuTransitions]} locale={locale} />
        </animated.nav>
      </React.Fragment>
    )
  )
}

export default MenuWrapper

export const ContentfulMenuItemFragment = graphql`
  fragment ContentfulMenuItemFragment on ContentfulMenuItem {
		__typename
    label
    externalLink
    internalLink
    link {
      ... on ContentfulPage {
        slug
        category {
          slug
        }
      }
    }
  }
`
