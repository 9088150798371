// Dependencies
import React from "react"
// Hookes
import useUpdateSizes from "../hooks/useUpdateSizes"
// import useSessionStorage from "../hooks/useSessionStorage"
// Components
import Header, { MenuButtons } from "../components/Header"
import Footer from "../components/Footer"
import Menu, { useMenuState } from "../components/Menu"
import Meta from "../components/Meta"
import VideoPlayer from "../components/VideoPlayer"
import Splash from "../components/Splash"
// CSS
import "../css/styles.scss"

const Layout = ({ 
	title, 
	description, 
	shareImage, 
	keywords, 
	locale,
	headerTitles, 
	colours, 
	isPage, 
	children 
}) => {
	
	// UX
  const [open, setOpen] = useMenuState();
	useUpdateSizes()

	// UI
  const handleMenuClick = () => {
    setOpen(o => !o)
  }

  return (
    <>
      <Meta title={title} description={description} shareImage={shareImage} keywords={keywords} colours={colours} locale={locale} />
      <MenuButtons open={open} handleClick={handleMenuClick} locale={locale} />
      <Menu open={open} handleMenuClick={handleMenuClick} locale={locale} />
      <Header open={open} headerTitles={headerTitles} handleMenuClick={handleMenuClick} locale={locale} />
      <main>{children}</main>
      <Footer isPage={isPage} locale={locale} />
      <VideoPlayer locale={locale} />
			<Splash locale={locale} />
    </>
  )
}

export default Layout
