import { graphql, useStaticQuery } from "gatsby";
import { EN, JP } from "../constants/locales";

// eslint-disable-next-line
export default function (locale) {
  const data = useStaticQuery(graphql`
    {
      settingsEn: allContentfulSettings(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
          ...ContentfulSettingsFragment
        }
      }
			settingsJp: allContentfulSettings(filter: {node_locale: {eq: "ja-JP"}}) {
        nodes {
          ...ContentfulSettingsFragment
        }
      }
    }
  `)
  return locale === EN ? data.settingsEn.nodes[0] : locale === JP ? data.settingsJp.nodes[0] : data.settingsEn.nodes[0]
}

export const ContentfulSettingsFragment = graphql`
	fragment ContentfulSettingsFragment on ContentfulSettings {
		## META
		defaultTitle: title
		defaultDescription: metaDescription {
			text: metaDescription
		}
		defaultKeywords: metaKeywords
		defaultShareImage: shareImage {
			file {
				url
			}
		}

		## Holding Page
		holdingPageCopy {
			childMarkdownRemark {
				html
			}
		}

		manifesto {
			raw
		}
		tagline {
			childMarkdownRemark {
				html
			}
		}

		## MENU
		menu {
			title
			menuItems {
				...ContentfulMenuItemFragment
			}
		}
		## FOOTER
		footerLinks {
			...ContentfulMenuItemFragment
		}
		signUpMessage {
			text: signUpMessage
		}
	}
`