// Dependencies
import React from "react";
// import { graphql } from "gatsby";
// import PropTypes from "prop-types";
// Hooks & Helpers
import colorsListToRGBValues from "../helpers/colorsListToRGBValues"

// https://yoksel.github.io/svg-gradient-map/#/
const GradientMap = ({ id, from, to }) => {

  const { r, g, b } = colorsListToRGBValues([from, to]);

  // const start = { r: 0.8, g: 1, b: 0.53, a: 0 };
  // const end = { r: 0.03, g: 0.57, b: 0.49, a: 1 };

  return (
    <svg className="gradient-map" xmlns="http://www.w3.org/2000/svg">
      <filter
        id={id}
        x="-10%" y="-10%"
        width="120%" height="120%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix 
					type="matrix" 
					values=".33 .33 .33 0 0
					.33 .33 .33 0 0
					.33 .33 .33 0 0
					0 0 0 1 0" 
					in="SourceGraphic" 
					result="colormatrix" 
				/>
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues={r} />
          <feFuncG type="table" tableValues={g} />
          <feFuncB type="table" tableValues={b} />
          <feFuncA type="table" tableValues={`0 1`} />
        </feComponentTransfer>
        <feBlend 
					mode="normal" 
					in="componentTransfer" 
					in2="SourceGraphic" 
					result="blend" 
				/>
      </filter>
    </svg>
  )
}

export default GradientMap
