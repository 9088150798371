// Dependencies
import React from "react"
import PropTypes from 'prop-types'
// import { Link } from "gatsby";
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from 'gatsby-source-contentful/rich-text'
// Hooks
// ....
// Components
import Artwork from "./Artwork";
import Image from "./Image";
import Link from "./LocaleLink";
import { PlayTrackButton } from "./MusicPlayer";

// MARKS
const Bold = ({ children }) => <strong>{children}</strong>;
const Italic = ({ children }) => <i>{children}</i>;

// BLOCKS
const P = ({ children }) => <p>{children}</p>;
const H1 = ({ children }) => <h1>{children}</h1>;
const H2 = ({ children }) => <h2>{children}</h2>;
const H3 = ({ children }) => <h3>{children}</h3>;
const H4 = ({ children }) => <h4>{children}</h4>;
const Quote = ({ children }) => <blockquote>{children}</blockquote>;

const PageLink = ({ category, slug, locale, children }) => {

	const to = category ? `/${category.slug}/${slug}` : `/${slug}`

	return (
		<Link to={to} locale={locale} className="btn">{children}</Link>
	)
}

const InlineEntryLink = ({ data, locale, children }) => {
	const type = data.target.__typename;

	if (!type) {
		// console.log(data)
		return <div className="debug">No type!</div>
	}

	if (type === 'ContentfulPage') {
		return (
			<PageLink {...data.target} locale={locale}>{children}</PageLink>
		)
	}

	if (type === 'ContentfulPlaylist') {
		return (
			<Link to={`/listen/${data.target.slug}`} locale={locale} className="btn">{children}</Link>
		)
	}

	if (type === 'ContentfulListenPage') {
		const to = data.target.slug === 'listen' ? `/listen` : `/listen/${data.target.slug}`
		return (
			<Link to={to} locale={locale} className="btn">{children}</Link>
		)
	}

	if (type === 'ContentfulTrack') {
		return (
			<Link to={`/track/${data.target.slug}`} locale={locale} className="btn">{children}</Link>
		)
	}

	if (type === 'ContentfulTrackArtist') {
		return (
			<Link to={`/artist/${data.target.slug}`} locale={locale} className="btn">{children}</Link>
		)
	}

	return (
		<div className="debug">{children}</div>
	)
}

// trigger

const EmbedCode = ({ embedCode, height, maxHeight, width }) => {

  const aspectRatio = width && height ? height / width : 9 / 16;

  return (
    <div className="embed" style={{ maxHeight: maxHeight || 'none' }}>
      <div className="pad" style={{ paddingTop: `${aspectRatio * 100}%` }} />
      <div className="embed__iframe" dangerouslySetInnerHTML={{__html: embedCode.embedCode}} />
    </div>
  )
}

const EmbeddedEntry = ({ data }) => {
	const type = data && data.target && data.target.__typename;

	if (!type) {
		// console.log(data)
		return <h1 className="debug">No type!</h1>
	}

	if (type === 'ContentfulTrack') {
		return <PlayTrackButton {...data.target} playlist={false} />
	}

	if (type === 'ContentfulEmbed') {
		return <EmbedCode {...data.target} />
	}

	if (type === 'ContentfulArtwork') {
		return <Artwork {...data.target} />
	}

  return (
    <h1 className="debug">{`Missing content for ${type}!`}</h1>
  )
}

const EmbeddedAsset = ({ data }) => {
	return data && data.target && (
		<div className="rich-text-asset mb">
			{/* <Img {...data.target} /> */}
			<Image image={data.target.large} alt="" />
    </div>
	)
}

const Hyperlink = (props) => {
  return (
    <a href={props.data.uri} target="_blank" rel="noopener noreferrer">{props.children}</a>
  )
}

const options = (props) => {

	return {
		renderMark: {
			[MARKS.BOLD]: text => <Bold>{text}</Bold>,
			[MARKS.ITALIC]: text => <Italic>{text}</Italic>,
		},
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <P>{children}</P>,
			[BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
			[BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
			[BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
			[BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
			[BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
			[BLOCKS.EMBEDDED_ENTRY]: (node) => <EmbeddedEntry {...node} />,
			[BLOCKS.EMBEDDED_ASSET]: (node) => <EmbeddedAsset {...node} />,
			[INLINES.HYPERLINK]: (node, children) => <Hyperlink {...node} {...props}>{children}</Hyperlink>,
			[INLINES.ENTRY_HYPERLINK]: (node, children) => <InlineEntryLink {...node} {...props}>{children}</InlineEntryLink>
		},
		renderText: text => text.split('\n')
			.reduce((children, textSegment, index) => [...children, index > 0 && <br key={index} />, textSegment], [])
	}
}

const RichText = ({ content, ...props }) => (
	content && (
		renderRichText(content, options(props))
	)
)

RichText.propTypes = {
  content: PropTypes.shape({
		raw: PropTypes.string.isRequired,
		references: PropTypes.array
	})
}

export default RichText
