// Dependencies
import React, { useState, useEffect, useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSpring, animated, config } from "react-spring"
import { easePolyInOut } from 'd3-ease'
// Hooks
import useSessionStorage from "../hooks/useSessionStorage"
// Components
import Artwork from "../components/Artwork"

const CONFIG = [
	config.molasses,
	{
		duration: 3500,
		easing: easePolyInOut.exponent(3)
	},
	{ mass: 1, tension: 200, friction: 100 },
	{ mass: 1, tension: 300, friction: 200, clamp: true },
	config.slow,
	{ mass: 30, tension: 300, friction: 280 },
]

const AnimatedPath = ({ d, offset, reverse }) => {

	const spring = useSpring({
		to: { x: 0 }, 
		from: { x: !reverse ? offset : -offset  }, 
		config: CONFIG[1]
	}) 

	return <animated.path d={d} strokeDashoffset={spring.x} strokeDasharray={offset} />
}

const Path = ({ d, reverse }) => {

	const [offset, setOffset] = useState(0)

	const ref = useCallback(node => {
		if (node) {
			setOffset(node.getTotalLength())
		}
		// eslint-disable-next-line
	}, [])

	return (
		offset > 0 ? (
			<AnimatedPath d={d} reverse={reverse} offset={offset} />
		) : (
			<path d={d} ref={ref} />
		)
	)
}

const Logo = () => {

	return (
		<div className="animated-logo">
			<svg stroke="black" strokeWidth={24} width="453" height="276" viewBox="0 0 453 276" fill="none" xmlns="http://www.w3.org/2000/svg">
				<Path d="M280.689 263.406H177V70.3919C177.428 51.2613 189.505 13 234.392 13C279.278 13 291.5 51.2613 292 70.3919V190.704C291.916 196.246 295.124 207.329 308.624 207.329C322.125 207.329 325.167 196.246 325.5 190.704V70.3919C326.095 51.2613 338.405 13 382.892 13C427.378 13 440.167 51.2613 440.5 70.3919V263.406L336.58 263.386" />
				<Path d="M128 70C128 102.033 102.033 128 70 128C37.9675 128 12 102.033 12 70C12 37.9675 37.9675 12 70 12C102.033 12 128 37.9675 128 70Z" />
				<Path d="M86 190.5C86 199.613 78.6127 207 69.5 207C60.3873 207 53 199.613 53 190.5C53 181.387 60.3873 174 69.5 174C78.6127 174 86 181.387 86 190.5Z" />
			</svg>
		</div>
	)
}

const MANIFESTO = [
	"Explores new ways to experience the world through sound and space.",
	"Creates collaboration between physical, tangible, imagined landscapes, and sonic [space is the] place; from this we seek new ways of reading the world around us.",
	"Invites criticism; inquiry; openness; suggestion; community.",
	"Believes that the quality of sound environments is as critical as quality air to breathe."
]
const COUNTDOWN = [
	"5...",
	"8...",
	"6...",
	"We are MUSICITY"
]

function useCounter(length, speed) {
	const [index, set] = useState(0)
	const finished = index === length - 1

	useEffect(() => {
		let timer;

		const progress = () => {
			if (index < length - 1) {
				set(index + 1)
			}
		}
		timer = setTimeout(progress, speed)
		
		return () => clearTimeout(timer)
		// eslint-disable-next-line
	}, [index])

	return [index, finished]
}

const SplashContent = ({ setSplash }) => {

	const [count, finished] = useCounter(4, 2000)

	useEffect(() => {
		let timer;
		if (finished) {
			timer = setTimeout(() => {
				setSplash(false)
			}, 2000)
		}
		return () => clearTimeout(timer)
		// eslint-disable-next-line
	}, [finished])

	return (
		<>
			<div className="splash-manifesto p1">
				<div className="caption">
					<p><strong>MSCTY </strong>{MANIFESTO[count]}</p>
				</div>
			</div>
			<div className="splash-logo">
				<Logo />
			</div>
			<div className="splash-countdown p1">
				<h1>{COUNTDOWN[count]}</h1>
			</div>
		</>
	)
}

const getRandom = (array) => array[Math.floor(Math.random() * array.length)]

const Splash = () => {

	// Content
	const artworks = useContent()
	const [artwork, setArtwork] = useState(null)

	useEffect(() => {
		setArtwork(
			getRandom(artworks)
		)
		// eslint-disable-next-line
	}, [])

	// UX
	const [showSplash, setSplash] = useState(false) // useSessionStorage('mscty_splash_page', true)
	const [render, setRender] = useState(showSplash)

	const handleTransitionEnd = () => {
		if (!showSplash) {
			setRender(false)
		}
	}

	// UI
	const handleClick = () => {
		setSplash(false)
	}

	// Style
	const colours = {
		color: artwork ? artwork.colours.primary : '#FFFFFF',
		backgroundColor: artwork ? artwork.colours.secondary : '#FFFFFF',
	}

	const style = {
		transform: !showSplash ? 'translateY(-100%)' : 'translateY(0%)',
		transition: '0.5s',
		...colours
	}

	return (
		render && (
			// eslint-disable-next-line
			<div className="splash-page" onClick={handleClick} onTransitionEnd={handleTransitionEnd} style={style}>
				<div className="splash-artwork">
					{artwork && <Artwork {...artwork} />}
				</div>
				<SplashContent setSplash={setSplash} />
			</div>
		)
	)
}

function useContent() {
	const data = useStaticQuery(graphql`
		{
			allContentfulSettings {
				nodes {
					contentful_id
					artworks: splashPageArtworks {
						...ContentfulArtworkFragment
					}
				}
			}
		}
	`)
	const { artworks } = data.allContentfulSettings.nodes[0]
	return artworks
}

export default Splash
